import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Table = ({ headings, beds }) => {
  return (
    <TableWrap>
      <thead>
        <tr>
          {headings.map((heading, i) => (
            <th key={`heading-${i}`}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {beds.map((bed, i) => {
          return (
            <>
              <tr key={`normal-${i}`}>
                <td key={`normal-${i}-cell-1`}>{bed.name} Bed</td>
                <td key={`normal-${i}-cell-2`}>
                  {bed.width}
                  cm x 188cm
                </td>
                <td key={`normal-${i}-cell-3`}>R {bed.price}</td>
              </tr>
              <tr key={`extraLength-${i}`}>
                <td key={`extraLength-${i}-cell-1`}>{bed.name} Bed XL</td>
                <td key={`extraLength-${i}-cell-2`}>
                  {bed.width}
                  cm x 200cm
                </td>
                <td key={`extraLength-${i}-cell-3`}>
                  R {bed.extraLengthPrice}
                </td>
              </tr>
            </>
          )
        })}
      </tbody>
    </TableWrap>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const TableWrap = styled.table`
  text-align: left;
  background: white;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 4px;
  overflow: hidden;
  min-width: 300px;
  width: 100%;
  margin: 40px 0;
  tr:not(:last-child) {
    border-bottom: 1px dashed #dee3e8;
  }

  td {
    padding: 10px 20px;
    font-size: 20px;
  }

  th {
    background-color: #223247;
    color: white;
    font-size: 25px;
    padding: 20px;
  }
  /*Mobile View*/
  @media only screen and (max-width: 760px) {
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      padding: 20px;
      > td:nth-of-type(1) {
        font-weight: bold;
      }
    }

    td {
      padding: 0;
      border: none;
      position: relative;
      text-align: left;
    }
  }
`

export default Table

import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import { getImg } from "./Panel"
import Dimensions from "./Dimensions"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const SpecialSizesRow = ({ title, content, beds, cta, cards }) => {
  return (
    <Container>
      <Inner>
        <Sizes>
          <h2>{title}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </Sizes>

        <Flex>
          {beds.map((bed, i) => (
            <Bed key={i}>
              <img src={getImg(`${bed.name}xl`)} />
              <h5>{bed.name} XL</h5>
              <Dimensions width={bed.width} length="200" />
            </Bed>
          ))}
        </Flex>
        <Cta>
          <h4>{cta.title}</h4>
          <Link to={cta.link}>{cta.label}</Link>
        </Cta>
        <Cards>
          {cards.map(({ card }, i) => (
            <Card
              className="left"
              key={i}
              dangerouslySetInnerHTML={{ __html: card }}
            />
          ))}
        </Cards>
      </Inner>
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Sizes = styled.div`
  text-align: center;
  color: #223247;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    margin-top: 40px;
    h2 {
      font-size: 25px;
      &:after {
        display: none;
        font-weight: bold;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
  h2 {
    text-align: center;
    font-size: 31px;
    &:after {
      margin: 0 auto;
      content: "";
      display: block;
      width: 60%;
      border-bottom: 2px solid red;
    }
  }
  p {
    max-width: 800px;
    margin: 15px 0;
  }
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  margin-top: 60px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
  }
  .left {
    text-align: left;
    display: block;
    h3 {
      font-weight: bold;
    }
    p {
      margin: 10px 0;
    }
  }
`

const Cta = styled.div`
  text-align: center;
  margin: 60px 0;
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  a {
    padding: 8px 12px;
    background-color: #ed1c24;
    border-radius: 4px;
    color: #ffffff;
    text-transform: uppercase;
  }
`

const Container = styled.div`
  padding: 60px 0;
`

const Card = styled.div`
  border-radius: 10px;
  background-color: #f0f1f3;
  text-align: center;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-size: 25px;
    font-weight: 400;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 60px 0;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    align-items: center;
    flex-direction: column;
    > div {
      margin: 40px 0;
    }
  }
`

const Bed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    padding: 20px;
  }

  h5 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`

export default SpecialSizesRow

import React from "react"

const IconPayments = ({ fill }) => (
  <svg width="15" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4 0c.68 0 1.22.55 1.22 1.22v8.94c0 .67-.54 1.21-1.21 1.21H1.2c-.66 0-1.21-.54-1.21-1.21V1.22C0 .55.55 0 1.22 0H13.4zM1.23.81a.4.4 0 00-.4.4v1.23h13V1.22a.4.4 0 00-.41-.4H1.2zm12.19 9.75a.4.4 0 00.4-.4V4.87h-13v5.29c0 .22.19.4.4.4h12.2zM4.87 8.43v.2a.3.3 0 01-.3.3H2.74a.3.3 0 01-.3-.3v-.2c0-.17.13-.3.3-.3h1.83c.17 0 .3.13.3.3zm4.88 0v.2a.3.3 0 01-.3.3H5.99a.3.3 0 01-.3-.3v-.2c0-.17.13-.3.3-.3h3.46c.16 0 .3.13.3.3z"
      fill={fill}
    />
  </svg>
)

export default IconPayments

import React from "react"
import Panel, { getImg } from "./Panel"
import styled from "styled-components"

const Tabs = ({ items }) => {
  const [active, setActive] = React.useState(0)
  const { name, description, width, relatedLinks } = items[active]
  return (
    <TabLayout>
      <TabItems>
        {items.map(({ name }, i) => (
          <Tab
            key={i}
            className={i === active && "active"}
            onClick={() => setActive(i)}
          >
            <img src={getImg(name)} />

            <h5>{name} Beds</h5>
          </Tab>
        ))}
      </TabItems>
      <Panel
        name={name}
        description={description}
        width={width}
        relatedLinks={relatedLinks}
        active={active}
      />
      {items.map((item, i) => (
        <Panel key={i} className="mobile" {...item} />
      ))}
    </TabLayout>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const TabLayout = styled.div`
  width: 100%;
`
const TabItems = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: none;
  }
`
const Tab = styled.div`
  width: 100%;
  cursor: pointer;
  img {
    padding: 15px;
  }
  h5 {
    padding: 10px;
    font-size: 19px;
    font-weight: 400;
  }
  &.active {
    h5 {
      color: #ed1c24;
      background: white;
      border-radius: 5px 5px 0 0;
    }
  }
`

export default Tabs

import React from "react"
import Layout from "../zzz/layout"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../zzz/organisms/heading"
import ThreeCardInfoRow from "../components/BedSizes/ThreeCardInfoRow"
import TabRow from "../components/BedSizes/TabRow"
import SpecialSizesRow from "../components/BedSizes/SpecialSizesRow"
import ComparisonRow from "../components/BedSizes/ComparisonRow"
import SEO from "../components/SEO/seo"
import MoonStar from "../zzz/atoms/icons/files/moon-star.jsx"
import Bed from "../zzz/atoms/icons/files/bed.jsx"
import Payments from "../zzz/atoms/icons/files/payments.jsx"
import { getImage } from "../lib/util"

const cardLayoutIcons = {
  "moon-star": MoonStar,
  bed: Bed,
  payments: Payments,
}
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const BedSizesPage = ({ location }) => {
  const {
    page: { seo, title, pageId, heading, bed_sizes_acf, featuredImage },
  } = useStaticQuery(PAGE_QUERY)

  const HEADING_ONE = "Bed or Mattress Size"
  const HEADING_TWO = "Bed Dimensions (cm)"
  const HEADING_THREE = "Prices Starting From"

  return (
    <Layout location={location}>
      <SEO title={title} location={location} post_id={pageId} seo={seo} />
      <Heading
        location={location}
        subTitle={heading.subTitle}
        image={getImage(featuredImage)}
      />
      <ComparisonRow
        title={bed_sizes_acf.comparisonTable.title}
        subTitle={bed_sizes_acf.comparisonTable.subTitle}
        headings={[HEADING_ONE, HEADING_TWO, HEADING_THREE]}
        beds={bed_sizes_acf.beds}
      />
      <ThreeCardInfoRow
        title={bed_sizes_acf.cardLayout.title}
        subTitle={bed_sizes_acf.cardLayout.body}
        body={bed_sizes_acf.cardLayout.subTitle}
        cards={bed_sizes_acf.cardLayout.cards}
        cardLayoutIcons={cardLayoutIcons}
      />
      <TabRow
        title={bed_sizes_acf.sizeGuide.title}
        subTitle={bed_sizes_acf.sizeGuide.subTitle}
        beds={bed_sizes_acf.beds}
      />

      <SpecialSizesRow
        title={bed_sizes_acf.specialSizes.title}
        content={bed_sizes_acf.specialSizes.content}
        beds={bed_sizes_acf.beds}
        cta={bed_sizes_acf.specialSizes.cta}
        cards={bed_sizes_acf.specialSizes.cards}
      />
    </Layout>
  )
}

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

//TablePress: create query to get bed sizes table from graphql
const PAGE_QUERY = graphql`
  {
    page: localWpGraphQlPages(slug: { eq: "bed-sizes" }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      pageId
      heading {
        subTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      bed_sizes_acf {
        comparisonTable {
          title
          subTitle
        }
        sizeGuide {
          title
          subTitle
        }
        specialSizes {
          title
          cards {
            card
          }
          content
          cta {
            title
            link
            label
          }
        }
        cardLayout {
          title
          subTitle
          body
          cards {
            icon
            body
            title
          }
        }
        beds {
          name
          description
          price
          extraLengthPrice
          width
          relatedLinks {
            label
            link
          }
        }
      }
    }
  }
`

export default BedSizesPage

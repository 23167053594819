import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"

const ThreeCardInfoRow = ({
  title,
  body,
  subTitle,
  cards,
  cardLayoutIcons,
}) => {
  return (
    <Container>
      {" "}
      <Inner>
        <Sizes>
          <h2>{title}</h2>
          <p>{body}</p>
          <h3>{subTitle}</h3>
          <Grid>
            {cards.map(({ title, icon, body }, i) => {
              return (
                <Card key={i}>
                  <Circle>
                    <SvgIcon SvgComponent={cardLayoutIcons[icon]} size="lg" />
                  </Circle>
                  <h4>{title}</h4>
                  <p>{body}</p>
                </Card>
              )
            })}
          </Grid>
        </Sizes>
      </Inner>
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 200px;
  background-color: #cfd5db;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const Card = styled.div`
  border-radius: 10px;
  background-color: #f0f1f3;
  text-align: center;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-size: 25px;
    font-weight: 400;
  }
`

const Container = styled.div`
  padding: 60px 0;
`

const Sizes = styled.div`
  text-align: center;
  color: #223247;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    margin-top: 40px;
    h2 {
      font-size: 25px;
      &:after {
        display: none;
        font-weight: bold;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
  h2 {
    text-align: center;
    font-size: 31px;
    &:after {
      margin: 0 auto;
      content: "";
      display: block;
      width: 60%;
      border-bottom: 2px solid red;
    }
  }
  p {
    max-width: 800px;
    margin: 15px 0;
  }
`

export default ThreeCardInfoRow

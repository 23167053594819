import React from "react"

const IconMoonStar = ({ fill }) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.13 11.65c.2 0 .32.24.19.4A5.23 5.23 0 010 8.76a5.25 5.25 0 016.2-5.16c.23.04.28.34.08.45a4.11 4.11 0 002.85 7.61zm-3.89 1.47c.79 0 1.54-.2 2.2-.59a5 5 0 01-2.97-8.09 4.38 4.38 0 00.77 8.68zm8.52-7.82c.15.07.24.22.24.39 0 .16-.1.31-.24.39l-1.33.66-.66 1.33a.44.44 0 01-.4.24.44.44 0 01-.39-.24l-.66-1.33-1.33-.66a.44.44 0 01-.24-.4c0-.16.1-.31.24-.38l1.33-.67.66-1.33c.15-.3.64-.3.79 0l.66 1.33 1.33.67zm-1.85.73l.67-.34-.67-.34a.46.46 0 01-.2-.2l-.34-.67-.33.67a.44.44 0 01-.2.2l-.67.34.67.33c.09.05.15.12.2.2l.34.68.33-.68a.44.44 0 01.2-.2zm-3.6-3.4l-.44-.88L7 1.31l.88-.44L8.3 0l.44.88.88.43-.88.44-.44.88z"
      fill={fill}
    />
  </svg>
)

export default IconMoonStar

import React from "react"
import styled from "styled-components"
import king from "../../images/beds/King.svg"
import queen from "../../images/beds/Queen.svg"
import single from "../../images/beds/Single.svg"
import double from "../../images/beds/Double.svg"
import threeQuarter from "../../images/beds/ThreeQuarter.svg"
import kingXl from "../../images/beds/KingXL.svg"
import queenXl from "../../images/beds/QueenXL.svg"
import singleXl from "../../images/beds/SingleXL.svg"
import doubleXl from "../../images/beds/DoubleXL.svg"
import threeQuarterXl from "../../images/beds/ThreeQuarterXL.svg"
import Dimensions from "./Dimensions"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Panel = ({
  name,
  description,
  width,
  relatedLinks,
  active,
  className,
}) => {
  return (
    <Content className={className}>
      <Title>{name}</Title>
      <img src={getImg(name)} />
      <Description>
        <p className="p" dangerouslySetInnerHTML={{ __html: description }} />
      </Description>
      <Bottom>
        <div className="dimensions">
          <Left>
            <h5>South African {name} size</h5>
            <h6>dimensions:</h6>
          </Left>
          <Right>
            <Dimensions width={width} />
          </Right>
        </div>
        <Range>
          <h5>View Our Range:</h5>
          {relatedLinks.map(({ link, label }, i) => (
            <Link key={link + width + i + active} to={link}>
              {label}
            </Link>
          ))}
        </Range>
      </Bottom>
    </Content>
  )
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
const includes = (str, data) =>
  data.replace(" ", "").toLowerCase().includes(str)

export const getImg = (name) => {
  if (includes("kingxl", name)) return kingXl
  if (includes("queenxl", name)) return queenXl
  if (includes("singlexl", name)) return singleXl
  if (includes("doublexl", name)) return doubleXl
  if (includes("threequarterxl", name)) return threeQuarterXl
  if (includes("king", name)) return king
  if (includes("queen", name)) return queen
  if (includes("single", name)) return single
  if (includes("double", name)) return double
  if (includes("threequarter", name)) return threeQuarter
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Content = styled.div`
  background: white;
  border-radius: 0 0 5px 5px 0 0;
  img {
    display: none;
  }
  &.mobile {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: none;
    &.mobile {
      display: block;
    }
    img {
      display: block;
      margin: 20px auto;
    }
    p.p {
      columns: 1;
    }
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  background-color: #223247;
  border-radius: 8px 8px 0 0;
  display: none;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 20px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: block;
  }
`
const Description = styled.div`
  padding: 30px;
  border-bottom: 1px dashed #dee3e8;

  p.p {
    width: 100%;
    max-width: 100%;
    columns: 2;
    margin: 0;
  }
`

const Range = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    background-color: #ed1c24;
    margin: 5px;
    border-radius: 4px;
    color: #ffffff;
    padding: 4px 10px;
  }
  h5 {
    font-size: 23px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
    > * {
      margin: 5px 0;
    }
  }
`

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div {
    padding: 30px;
  }
  > div:not(:last-child) {
    border-right: 1px dashed #dee3e8;
  }

  .dimensions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;

    > div:not(:last-child) {
      border-right: none;
      border-bottom: 1px dashed #dee3e8;
    }
  }
`

const Left = styled.div`
  h5 {
    font-size: 23px;
  }
  h6 {
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Panel

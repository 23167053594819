import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import Table from "./Table"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ComparisonRow = ({ title, subTitle, headings, beds }) => {
  return (
    <Grey>
      <Inner>
        <Sizes>
          <h2>{title}</h2>
          <p>{subTitle}</p>

          <Table headings={headings} beds={beds} />
        </Sizes>
      </Inner>
    </Grey>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Sizes = styled.div`
  text-align: center;
  color: #223247;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    margin-top: 40px;
    h2 {
      font-size: 25px;
      &:after {
        display: none;
        font-weight: bold;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
  h2 {
    text-align: center;
    font-size: 31px;
    &:after {
      margin: 0 auto;
      content: "";
      display: block;
      width: 60%;
      border-bottom: 2px solid red;
    }
  }
  p {
    max-width: 800px;
    margin: 15px 0;
  }
`

const Grey = styled.div`
  background: #f6f6f6;
  padding: 60px 0;
`

export default ComparisonRow

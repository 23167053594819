import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Dimensions = ({ width, length = 188 }) => {
  return (
    <SizeWrap>
      <div>
        <span>
          {width}
          cm
        </span>
        <Label>Width</Label>
      </div>
      <div className="x">x</div>
      <div>
        <span>
          {length}
          cm
        </span>
        <Label>Length</Label>
      </div>
    </SizeWrap>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const SizeWrap = styled.div`
  display: flex;
  color: #223247;

  .x {
    padding: 10px;
  }

  span {
    display: block;
    padding: 4px 15px;
    font-size: 16px;
    color: #223247;
  }
`
const Label = styled.div`
  font-size: 10px;
  background-color: #e8e8e8;
  border-radius: 2px;
  color: #77848e;
  text-transform: uppercase;
  padding: 4px;
  text-align: center;
`

export default Dimensions
